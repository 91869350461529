import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { BaseService } from "./base.service";
import { ILogData } from "../interfaces/log.interface";
import { IListWithPaginationResponse } from "../interfaces/main.interface";

/** Abstract class to be used like crud base methods for other services providing generic informations and easily the development and organization */
export abstract class CrudBaseService<T> extends BaseService {
    /** Api Url */
    protected readonly apiUrl: string;

    /** @ignore */
    constructor(protected readonly http: HttpClient, apiBaseUrl: string, apiPath: string) {
        super();
        this.apiUrl = `${apiBaseUrl}${apiPath}`;
    }

    /**
     * Returns a object by id
     * @param {string} id - Item id that will be returned
     * @returns Observable<T>
     */
    listById(id: string): Observable<T> {
        return this.http
            .get(`${this.apiUrl}/${id}`)
            .pipe(
                map((data) => data as T),
                catchError((err: HttpErrorResponse) => this.serviceError(err))
            );
    }

    /**
     * Returns a list of objects from the current generic path
     * @param {string} queryParams - Optional query params to filter
     * @param {number} page - Optional pagination page
     * @param {number} limit - Optional pagination limit
     * @returns Observable<IListWithPaginationResponseT[]>
     */
    list(queryParams?: string, page?: number, limit?: number): Observable<IListWithPaginationResponse<T>> {
        let url = this.apiUrl;
        const params: string[] = [];

        if (queryParams) params.push(queryParams);
        if (page) params.push(`page=${page}`);
        if (limit) params.push(`limit=${limit}`);
        if (params.length > 0) url += '?' + params.join('&');

        return this.http
            .get(url)
            .pipe(
                map((data) => data as IListWithPaginationResponse<T>),
                catchError((err: HttpErrorResponse) => this.serviceError(err))
            );
    }

    /**
     * Insert object from the current generic path
     * @returns IInsertResponse
     */
    insert(item: T): Observable<T/*IInsertResponse*/> {
        return this.http.post(this.apiUrl, item).pipe(
            map((res) => res as T /*IInsertResponse*/),
            catchError((err: HttpErrorResponse) => this.serviceError(err))
        );
    }

    /**
     * Update object from the current generic path
     * @param {string} id - Item id that will be updated
     * @param {T} item - Item object to update
     * @returns boolean that represents if the update was successful
     */
    update(id: string, item: T): Observable<boolean> {
        return this.http.put(this.apiUrl + "/" + id, item).pipe(
            map(() => true),
            catchError((err: HttpErrorResponse) => this.serviceError(err))
        );
    }

    /**
     * Returns log by id
     * @param {string} id - Item id that will be returned
     * @returns ILogData<T>
     */
    listLogById(id: string): Observable<ILogData[]> {
        return this.http
            .get(`${this.apiUrl}/logs/${id}`)
            .pipe(
                map((data) => data as ILogData[]),
                catchError((err: HttpErrorResponse) => this.serviceError(err))
            );
    }
}
