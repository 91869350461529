import {
    Component,
    Output,
    EventEmitter,
    Input,
    ViewEncapsulation,
} from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";

import { AuthService } from "@auth0/auth0-angular";
import { MatDialog } from "@angular/material/dialog";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgScrollbarModule } from "ngx-scrollbar";
import { TablerIconsModule } from "angular-tabler-icons";
import { Subject } from "rxjs";

import { MaterialModule } from "src/app/material.module";
import { CoreService } from "src/app/shared/services/core.service";
import { UserSettingsService } from "src/app/shared/services/main/user-settings.service";
import { ILanguage } from "src/app/shared/interfaces/i18n.interface";
import { IAppSettings } from "src/app/app.config";
import { IUserSettingsRequestBody } from "src/app/shared/interfaces/user-settings.interface";
import { AppVerticalSearchDialogComponent } from "./search-dialog/search-dialog.component";

/**
 * HeaderComponent is a standalone component that represents the application header.
 */
@Component({
    selector: "app-header",
    standalone: true,
    imports: [
        RouterModule,
        CommonModule,
        TranslateModule,
        NgScrollbarModule,
        TablerIconsModule,
        MaterialModule,
    ],
    templateUrl: "./header.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
    /** Indicates if the toggle button should be shown. */
    @Input() showToggle = true;
    /** Indicates if the toggle button is checked. */
    @Input() toggleChecked = false;
    /** Event emitter for options change. */
    @Output() optionsChange = new EventEmitter<IAppSettings>();
    /** Event emitter for toggling the mobile navigation. */
    @Output() toggleMobileNav = new EventEmitter<void>();
    /** Event emitter for toggling the mobile filter navigation. */
    @Output() toggleMobileFilterNav = new EventEmitter<void>();
    /** Event emitter for toggling the collapsed state. */
    @Output() toggleCollapsed = new EventEmitter<void>();
    /** Indicates if the filler should be shown. */
    showFiller = false;
    /** Application settings. */
    options!: IAppSettings;

    /** @ignore */
    private updateUserSettingsSubject: Subject<IUserSettingsRequestBody> = new Subject<IUserSettingsRequestBody>();

    /** Currently selected language. */
    public selectedLanguage: ILanguage = {
        language: "English",
        code: "en",
        type: "US",
        icon: "/assets/images/flag/icon-flag-en.svg",
    };

    /** List of available languages. */
    public languages: ILanguage[] = [
        {
            language: "English",
            code: "en",
            type: "US",
            icon: "/assets/images/flag/icon-flag-en.svg",
        },
        {
            language: "Português",
            code: "pt",
            type: "PT",
            icon: "/assets/images/flag/icon-flag-br.svg",
        },
    ];

    /** @ignore */
    constructor(
        public dialog: MatDialog,
        public authService: AuthService,
        private translate: TranslateService,
        private settings: CoreService,
        private userSettingsService: UserSettingsService
    ) {
        this.initUpdateUserSettings();
        this.onSettingsChange();
        this.options = this.settings.getOptions();
        translate.setDefaultLang(this.options.language);
        translate.use(this.options.language);
    }

    /** Opens the search dialog. */
    openDialog() {
        const dialogRef = this.dialog.open(AppVerticalSearchDialogComponent);
        dialogRef.afterClosed().subscribe(() => {});
    }

    /**
     * Changes the application language.
     * @param lang The language to change to.
     */
    changeLanguage(lang: ILanguage): void {
        this.options.language = lang.code;
        this.translate.use(lang.code);
        this.selectedLanguage = lang;
        this.updateUserSettingsSubject.next({ language: this.options.language });
    }

    /** Sets the application theme to dark. */
    setDark() {
        this.options.theme = "dark";
        this.optionsChange.emit(this.options);
        this.updateUserSettingsSubject.next({ theme: this.options.theme });
    }

    /** Sets the application theme to light. */
    setLight() {
        this.options.theme = "light";
        this.optionsChange.emit(this.options);
        this.updateUserSettingsSubject.next({ theme: this.options.theme });
    }

    /** Open current page in new tab */
    openCurrentPageInNewTab() {
        const currentUrl = window.location.href;
        window.open(currentUrl, '_blank');
    }

    /** Initializes the update of user settings with a debounced subject. */
    private initUpdateUserSettings() {
        this.updateUserSettingsSubject.subscribe((data) => {
            this.userSettingsService.update(data).subscribe();
        });
    }

    /** Handles changes in user settings and updates the application state accordingly. */
    private onSettingsChange() {
        this.settings.notify.subscribe((settings) => {
            if (settings && settings.language) {
                const currentLanguage = this.languages.find(language => language.code === settings.language);
                if (currentLanguage) this.selectedLanguage = currentLanguage;
                if (this.options) {
                    this.options.language = settings.language as string;
                    this.translate.use(this.options.language);
                }
            }
        });
    }
}