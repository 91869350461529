import { NgModule } from "@angular/core";
// Material Form Controls
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
	MatNativeDateModule,
} from "@angular/material/core";
import {
	MAT_FORM_FIELD_DEFAULT_OPTIONS,
	MatFormFieldModule,
} from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgOptionHighlightModule } from "@ng-select/ng-option-highlight";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
// Material Navigation
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
// Material Layout
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatListModule } from "@angular/material/list";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTreeModule } from "@angular/material/tree";
// Material Buttons & Indicators
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatBadgeModule } from "@angular/material/badge";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRippleModule } from "@angular/material/core";
// Material Popups & Modals
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import {
	MatDialogModule,
	MAT_DIALOG_DEFAULT_OPTIONS,
} from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
// Material Data tables
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
// Translate Service
import { TranslateService } from "@ngx-translate/core";
// Date Formats
import { CustomDateAdapter } from "./shared/services/custom-date-adapters.service";
import { OwlDateTimeModule, OwlNativeDateTimeModule, DateTimeAdapter, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS, OwlDateTimeIntl } from "@danielmoncada/angular-datetime-picker";
import { DefaultIntlAdapter } from "./shared/services/owl-datetime-intl-adapter.service";

/**
 * Custom date formats for Angular Material datepicker.
 */
const dateFormats = {
	parse: {
		dateInput: "DD/MM/YYYY",
	},
	display: {
		dateInput: "DD/MM/YYYY",
		monthYearLabel: "MMM YYYY",
		dateA11yLabel: "LL",
		monthYearA11yLabel: "MMMM YYYY",
	},
};

/**
 * Custom date formats for Owl datetime-picker.
 */
export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm',
    fullPickerInput: 'DD/MM/YYYY HH:mm',
    datePickerInput: 'DD/MM/YYYY',
    timePickerInput: 'HH:mm',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
};

/**
 * NgModule that includes all necessary Angular Material modules for the application.
 */
@NgModule({
	declarations: [],
	exports: [
		MatAutocompleteModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatInputModule,
		MatRadioModule,
		MatSelectModule,
		NgSelectModule,
		NgOptionHighlightModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatMenuModule,
		MatSidenavModule,
		MatToolbarModule,
		MatCardModule,
		MatDividerModule,
		MatExpansionModule,
		MatGridListModule,
		MatListModule,
		MatStepperModule,
		MatTabsModule,
		MatTreeModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatBadgeModule,
		MatChipsModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatProgressBarModule,
		MatRippleModule,
		MatBottomSheetModule,
		MatDialogModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatPaginatorModule,
		MatSortModule,
		MatTableModule,
	],
	providers: [
		{ provide: MAT_DATE_LOCALE, useValue: "en" },
		{ provide: MAT_DATE_FORMATS, useValue: dateFormats },
		{ provide: OWL_DATE_TIME_LOCALE, useValue: 'en' },
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
		{ provide: DateTimeAdapter, useClass: CustomDateAdapter },
		{ provide: OwlDateTimeIntl, useClass: DefaultIntlAdapter },
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: {
				disableClose: true,
				hasBackdrop: true,
				backdropClass: "mat-dialog-backdrop-custom",
			},
		},
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				subscriptSizing: "dynamic",
			},
		},
	],
})
export class MaterialModule {
	/** @ignore */
	constructor(
		private translateService: TranslateService,
		private adapter: DateAdapter<Date>,
		private dateTimeAdapter: DateTimeAdapter<any>
	) {
		this.translateService.onLangChange.subscribe(() => {
			this.adapter.setLocale(this.translateService.currentLang);
			this.dateTimeAdapter.setLocale(this.translateService.currentLang);
		});
	}
}