import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute, Data } from "@angular/router";
import { Title } from "@angular/platform-browser";

import { filter, map, mergeMap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

/**
 * Breadcrumb component used to display navigation breadcrumbs.
 */
@Component({
	selector: "app-breadcrumb",
	templateUrl: "./breadcrumb.component.html",
	styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
	/** Current i18n title */
	private currentTitle = "";

	/** Information about the current page */
	pageInfo: Data | null = null;
	/** The segments of the current URL */
	myurl: string[];

	/** @ignore */
	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private titleService: Title,
		private translateService: TranslateService
	) {
		this.myurl = this.router.url.slice(1).split("/");
	}

	/** @ignore */
	ngOnInit() {
		this.translateService.onLangChange.subscribe(() => {
			this.titleService.setTitle(this.translateService.instant(this.currentTitle) as string);
		});
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.pipe(map(() => this.activatedRoute))
			.pipe(
				map((route) => {
					while (route.firstChild) {
						route = route.firstChild;
					}
					return route;
				})
			)
			.pipe(filter((route) => route.outlet === "primary"))
			.pipe(mergeMap((route) => route.data))
			.subscribe((event) => {
				this.currentTitle = event["title"] as string;
				this.titleService.setTitle(this.translateService.instant(event["title"] as string) as string);
				this.pageInfo = event;
			});
		this.updateBreadcrumb(this.activatedRoute);
	}

	/**
	 * Updates the breadcrumb information based on the current route.
	 * @param route The current activated route.
	 */
	private updateBreadcrumb(route: ActivatedRoute) {
		let currentRoute = route;
		while (currentRoute.firstChild) {
			currentRoute = currentRoute.firstChild;
		}
		currentRoute.data.subscribe((data) => {
			this.currentTitle = data["title"] as string;
			this.titleService.setTitle(this.translateService.instant(data["title"] as string) as string);
			this.pageInfo = data;
		});
	}
}