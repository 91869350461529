import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { TranslateModule } from "@ngx-translate/core";
import { AgGridModule } from "ag-grid-angular";
import { TablerIconsModule } from "angular-tabler-icons";
import { NgScrollbarModule } from "ngx-scrollbar";
import { provideEnvironmentNgxMask, NgxMaskDirective, NgxMaskPipe } from "ngx-mask";

import { MaterialModule } from "../material.module";
import { TextTruncateTooltipDirective } from "./directives/text-truncate-tooltip.directive";
import { HasPermissionDirective } from "./directives/has-permission.directive";
import { ButtonHasPermissionDirective } from "./directives/button-has-permission.directive";
import { CustomDatePickerButtonDirective } from "./directives/custom-buttons-date-picker.directive";
import { CustomDateTimePickerButtonDirective } from "./directives/custom-buttons-datetime-picker.directive";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { CustomizerComponent } from "./components/customizer/customizer.component";
import { LabelComponent } from "./components/label/label.component";
import { NgSelectMultiViewComponent } from "./components/ng-select-multi-view/ng-select-multi-view.component";
import { GridComponent } from "./components/grid/grid.component";
import { GridButtonComponent } from "./components/grid/grid-button/grid-button.component";
import { GridFilterComponent } from "./components/grid/grid-filter/grid-filter.component";
import { GridFilterDialogComponent } from "./components/grid/grid-filter/grid-filter-dialog/grid-filter-dialog.component";
import { GridQuickFilterComponent } from "./components/grid/grid-quick-filter/grid-quick-filter.component";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { ShareDialogComponent } from "./components/share-dialog/share-dialog.component";
import { DatetimePickerComponent } from "./components/datetime-picker/datetime-picker.component";
import { FormHeaderComponent } from "./components/form-header/form-header.component";
import { MultilineComponent } from "./components/multiline/multiline.component";
import { LogViewerComponent } from "./components/log-viewer/log-viewer.component";
import { ComplexObjectModalComponent } from "./components/log-viewer/complex-object-modal/complex-object-modal.component";
import { ViewCardComponent } from "./components/view-card/view-card.component";
import { ViewCardRowComponent } from "./components/view-card-row/view-card-row.component";
import { GridFilterService } from "./services/main/grid-filter.service";
import { ConcatPipe } from "./pipe/concat.pipe";
import { YesOrNoPipe } from "./pipe/yes-or-no.pipe";

@NgModule({
	declarations: [
		BreadcrumbComponent,
		CustomizerComponent,
		LabelComponent,
		TextTruncateTooltipDirective,
		HasPermissionDirective,
		ButtonHasPermissionDirective,
		CustomDatePickerButtonDirective,
		CustomDateTimePickerButtonDirective,
		NgSelectMultiViewComponent,
		GridComponent,
		GridButtonComponent,
		GridFilterComponent,
		GridFilterDialogComponent,
		GridQuickFilterComponent,
		ConfirmationDialogComponent,
		ShareDialogComponent,
		DatetimePickerComponent,
		FormHeaderComponent,
		MultilineComponent,
		LogViewerComponent,
		ComplexObjectModalComponent,
		ViewCardComponent,
		ViewCardRowComponent,
	],
	imports: [
		RouterModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		AgGridModule,
		TablerIconsModule,
		NgScrollbarModule,
		MaterialModule,
		NgxMaskDirective,
		NgxMaskPipe,
		ConcatPipe,
		YesOrNoPipe
	],
	exports: [
		BreadcrumbComponent,
		CustomizerComponent,
		LabelComponent,
		TextTruncateTooltipDirective,
		HasPermissionDirective,
		ButtonHasPermissionDirective,
		CustomDatePickerButtonDirective,
		CustomDateTimePickerButtonDirective,
		NgSelectMultiViewComponent,
		GridComponent,
		GridButtonComponent,
		GridFilterComponent,
		GridFilterDialogComponent,
		GridQuickFilterComponent,
		ConfirmationDialogComponent,
		ShareDialogComponent,
		DatetimePickerComponent,
		FormHeaderComponent,
		MultilineComponent,
		LogViewerComponent,
		NgxMaskDirective,
		NgxMaskPipe,
		ViewCardComponent,
		ViewCardRowComponent,
		ConcatPipe,
		YesOrNoPipe
	],
	providers: [provideEnvironmentNgxMask(), GridFilterService],
})
export class SharedModule {}
