import { Component } from "@angular/core";

import { AuthService as Auth0Service } from "@auth0/auth0-angular";
import { NgxSpinnerService } from "ngx-spinner";

import { AuthService } from "./shared/services/auth.service";
import { VersionCheckService } from "./shared/services/version-check.service";

import { environment } from "src/environments/environment";

/**
 * AppComponent is the root component of the application.
 */
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
})
export class AppComponent {
    /** The title of the application. */
    public title = "Allog CRM";

    /** @ignore **/
    constructor(private auth0Service: Auth0Service, private authService: AuthService, private spinnerService: NgxSpinnerService, private versionCheckService: VersionCheckService) {
        this.auth0Service.isAuthenticated$.subscribe((isAuthenticated) => {
            if (isAuthenticated) {
                this.spinnerService.show();
                this.authService.setSession().subscribe(() => {
                    this.versionCheckService.initVersionCheck(60000);
                    this.spinnerService.hide();
                });
            }
        });
        this.auth0Service.error$.subscribe(error => {
            if (error.message?.includes('client requires organization membership')) this.handleInvalidRequestError();
        });
    }

    /** @ignore */
    private handleInvalidRequestError() {
        this.auth0Service.logout({ logoutParams: { returnTo: `${environment.frontUrl}authentication/error?no_organization=true` } });
    }
}