import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthService } from "../services/auth.service";

/**
 * HasPermissionDirective is used to conditionally include an Angular template
 * based on the user's permissions.
 */
@Directive({
    selector: "[appHasPermission]",
})
export class HasPermissionDirective {
    /** @ignore **/
    private permissions: string[] = [];

    /** @ignore **/
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService
    ) {}

    /**
     * Input property to set the required permissions.
     * @param permissions List of required permissions.
     */
    @Input() set appHasPermission(permissions: string[]) {
        this.permissions = permissions;
        this.updateView();
    }

    /**
     * Updates the view based on the user's permissions.
     */
    private updateView(): void {
        if (this.permissions.length === 0 || this.permissions.some(permission => this.authService.hasPermission(permission))) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}