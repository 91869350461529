import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import { Observable, catchError, map } from "rxjs";

import { environment } from "src/environments/environment";
import { BaseService } from "../../base/base.service";
import { IUserSettings, IUserSettingsRequestBody } from "../../interfaces/user-settings.interface";

/** Responsible for making the user-settings manipulation methods available by the main api */
@Injectable({
	providedIn: "root",
})
export class UserSettingsService extends BaseService {
	/** Api Url */
	protected readonly apiUrl!: string;

	/** @ignore */
	constructor(protected readonly http: HttpClient) {
		super();
		this.apiUrl = `${environment.mainApiUrl}userSettings`;
	}

    /**
     * Return user-settings data
     * @returns User settings as IUserSettings
     */
    list = (): Observable<IUserSettings> => {
        return this.http
            .get(this.apiUrl)
            .pipe(
                map((userSettings) => userSettings as IUserSettings),
                /* eslint-disable-next-line @typescript-eslint/unbound-method */
                catchError(super.serviceError)
            );
    };

    /**
     * Update user-settings data
     * @param {IUserSettingsRequestBody} userSettings - User settings data to be updated
     * @returns User settings as any
     */
    update = (userSettings: IUserSettingsRequestBody): Observable<boolean> => {
        return this.http.put(this.apiUrl, userSettings).pipe(
            map(() => true),
            catchError((err: HttpErrorResponse) => this.serviceError(err))
        );
    };
}
