import {
    Component,
    HostBinding,
    Input,
    OnChanges,
    Output,
    EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";
import { CommonModule } from "@angular/common";
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from "@angular/animations";

import { TranslateModule } from "@ngx-translate/core";
import { TablerIconsModule } from "angular-tabler-icons";

import { MaterialModule } from "src/app/material.module";
import { INavItem } from "./nav-item";
import { NavService } from "../../../../../shared/services/nav.service";

/**
 * AppNavItemComponent is a standalone component that represents a navigation item in a menu.
 */
@Component({
    selector: "app-nav-item",
    standalone: true,
    imports: [TranslateModule, TablerIconsModule, MaterialModule, CommonModule],
    templateUrl: "./nav-item.component.html",
    styleUrls: [],
    animations: [
        trigger("indicatorRotate", [
            state("collapsed", style({ transform: "rotate(0deg)" })),
            state("expanded", style({ transform: "rotate(180deg)" })),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
            ),
        ]),
    ],
})
export class AppNavItemComponent implements OnChanges {
    /** Indicates if the navigation item is expanded. */
    expanded = false;
    /** Indicates if the navigation item is disabled. */
    disabled = false;
    /** Indicates if the navigation item has two lines. */
    twoLines = false;
    
    /** Event emitter for toggling mobile link. */
    @Output() toggleMobileLink: any = new EventEmitter<void>();
    /** Event emitter for notifying about the expansion state. */
    @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

    /** ARIA attribute to indicate if the navigation item is expanded. */
    @HostBinding("attr.aria-expanded") ariaExpanded = this.expanded;

    /** Navigation item input. */
    @Input() item!: INavItem;
    /** Depth level of the navigation item. */
    @Input() depth: any;

    /** @ignore */
    constructor(public navService: NavService, public router: Router) {
        if (this.depth === undefined) {
            this.depth = 0;
        }
    }

    /** @ignore */
    ngOnChanges() {
        this.navService.currentUrl.subscribe((url: string) => {
            if (this.item.route && url) {
                this.expanded = url.indexOf(`/${this.item.route}`) === 0;
                this.ariaExpanded = this.expanded;
            }
        });
    }

    /**
     * Handles the selection of a navigation item.
     * @param item The selected navigation item.
     */
    onItemSelected(item: INavItem) {
		if (typeof item.action === 'function') {
			item.action();
        }
        if (item.route && item.route.length && (!item.children || !item.children.length)) {
            this.router.navigate([item.route]);
        }
        if (item.children && item.children.length) {
            this.expanded = !this.expanded;
        }

        // Scroll to top
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        if (!this.expanded) {
            if (window.innerWidth < 1024) {
                this.notify.emit();
            }
        }
    }

    /**
     * Handles the selection of a sub-navigation item.
     * @param item The selected sub-navigation item.
     */
    onSubItemSelected(item: INavItem) {
        if (!item.children || !item.children.length) {
            if (this.expanded && window.innerWidth < 1024) {
                this.notify.emit();
            }
        }
    }
}