import { Component } from "@angular/core";

import { IAppSettings } from "src/app/app.config";
import { CoreService } from "src/app/shared/services/core.service";

/**
 * BrandingComponent is a standalone component that represents the application branding, including the logo.
 */
@Component({
    selector: "app-branding",
    standalone: true,
    imports: [],
    template: `
        <div class="branding">
            <a href="/">
                <img
                    src="./assets/images/logos/allog-logo.svg"
                    style="margin-left: -4px !important;"
                    width="160"
                    height="65"
                    priority
                    class="align-middle"
                    alt="logo" />
            </a>
        </div>
    `,
})
export class BrandingComponent {
    /** Application settings. */
    options!: IAppSettings;

    /** @ignore */
    constructor(private settings: CoreService) {
        this.options = this.settings.getOptions();
    }
}