<div class="horizontal-sidebar p-y-16 b-b-1" *ngIf="mobileQuery.matches">
  <div class="container">
    <div >
      <div class="horizontal-navbar hstack align-items-center">
        <div *ngFor="let item of navItems">
          <app-horizontal-nav-item *appHasPermission="item.permissions ?? []"
            [item]="item"
            class="parentBox {{ item.ddType }}"
          >
          </app-horizontal-nav-item>
        </div>
      </div>
    </div>
  </div>
</div>
