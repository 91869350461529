import { Injectable, inject } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { AuthService as Auth0Service } from "@auth0/auth0-angular";
import { TranslateService } from "@ngx-translate/core";
import { jwtDecode } from "jwt-decode";
import {
	BehaviorSubject,
	Observable,
	catchError,
	from,
	tap,
	throwError,
} from "rxjs";

import { NotificationService } from "./notification.service";

/** @ignore */
interface JWTDecoded {
	privileges: string[];
	permissions: string[];
}

/**
 * Service for handling authentication with Auth0.
 * @export
 * @class AuthService
 */
@Injectable({
	providedIn: "root",
})
export class AuthService {
	/** @ignore **/
	private sessionReadySource = new BehaviorSubject<boolean>(false);
	/** @ignore **/
	private auth0Service = inject(Auth0Service);
	/** @ignore **/
	private notificationService = inject(NotificationService);
	/** @ignore **/
	private translateService = inject(TranslateService);

	/** @ignore */
	constructor() {
		this.auth0Service.isAuthenticated$.subscribe((isAuthenticated) => {
			if (!isAuthenticated) localStorage.clear();
		});
	}

	/** @ignore **/
	public sessionReady$ = this.sessionReadySource.asObservable();

	/**
	 * Sets up the user session by getting the access token and storing user roles and permissions.
	 * @public
	 * @memberof AuthService
	 */
	public setSession(): Observable<void> {
		return new Observable((observer) => {
			this.auth0Service
				.getAccessTokenSilently({ cacheMode: "off" })
				.pipe(
					catchError((error: HttpErrorResponse) => {
						this.notificationService.notifyError(
							this.translateService.instant(
								"NOTIFICATION.SESSION_EXPIRED"
							) as string
						);
						this.auth0Service.logout();
						return throwError(() => error);
					})
				)
				.subscribe((token) => {
					const decodedToken = jwtDecode<JWTDecoded>(token);
					localStorage.setItem("lead.user.roles", JSON.stringify(decodedToken.privileges || []));
					const rawPermissions = decodedToken.permissions || [];
					// Remove everything after ":", and filter out duplicates
					const cleanedPermissions = Array.from(new Set(rawPermissions.map((permission: string) => permission.split(":")[0])));
					localStorage.setItem("lead.user.permissions", JSON.stringify(cleanedPermissions));
					observer.next();
					observer.complete();
					this.sessionReadySource.next(true);
				});
		});
	}

	/**
	 * Handles authentication by processing the redirect callback.
	 * @public
	 * @return {Observable<any>} Observable of the authentication result.
	 * @memberof AuthService
	 */
	public handleAuthentication(): Observable<any> {
		return from(this.auth0Service.handleRedirectCallback()).pipe(
			tap((result) => {
				if (result.appState && result.appState.target) {
					window.location.href = result.appState.target as string;
				} else {
					window.location.href = "/";
				}
			})
		);
	}

	/**
	 * Retrieves user roles from localStorage.
	 * @public
	 * @return {string[]} Array of user roles.
	 * @memberof AuthService
	 */
	public getRoles(): string[] {
		const roles = localStorage.getItem("lead.user.roles");
		return roles ? (JSON.parse(roles) as string[]) : [];
	}

	/**
	 * Checks if the user has a specific role.
	 * @public
	 * @param {string} role - Role to check.
	 * @return {boolean} True if the user has the role, false otherwise.
	 * @memberof AuthService
	 */
	public hasRole(role: string): boolean {
		const roles = localStorage.getItem("lead.user.roles");
		return roles ? roles.includes(role) : false;
	}

	/**
	 * Retrieves user permissions from localStorage.
	 * @public
	 * @return {string[]} Array of user permissions.
	 * @memberof AuthService
	 */
	public getPermissions(): string[] {
		const permissions = localStorage.getItem("lead.user.permissions");
		return permissions ? (JSON.parse(permissions) as string[]) : [];
	}

	/**
	 * Checks if the user has a specific permission.
	 * @public
	 * @param {string} permission - Permission to check.
	 * @return {boolean} True if the user has the permission, false otherwise.
	 * @memberof AuthService
	 */
	public hasPermission(permission: string): boolean {
		const permissions = localStorage.getItem("lead.user.permissions");
		return permissions ? permissions.includes(permission) : false;
	}
}