<mat-sidenav-container *ngIf="readyToRender"
  class="mainWrapper"
  autosize
  autoFocus
  [ngClass]="{
    'sidebarNav-mini': options.sidenavCollapsed && options.navPos !== 'top',
    'sidebarNav-horizontal': options.horizontal,
    cardBorder: options.cardBorder,
    orange_theme: options.activeTheme === 'orange_theme',
    blue_theme: options.activeTheme === 'blue_theme',
    aqua_theme: options.activeTheme === 'aqua_theme',
    purple_theme: options.activeTheme === 'purple_theme',
    green_theme: options.activeTheme === 'green_theme',
    cyan_theme: options.activeTheme === 'cyan_theme'
  }"
  [dir]="options.dir!"
>
  <!-- ============================================================== -->
  <!-- Vertical Sidebar -->
  <!-- ============================================================== -->
  <mat-sidenav
    #leftsidenav
    [mode]="isOver ? 'over' : 'side'"
    [opened]="
      options.navPos === 'side' &&
      options.sidenavOpened &&
      !isOver
    "
    *ngIf="!options.horizontal"
    (openedChange)="onSidenavOpenedChange($event)"
    (closedStart)="onSidenavClosedStart()"
    class="sidebarNav"
  >
    <div class="flex-layout">
      <app-sidebar></app-sidebar>
      <ng-scrollbar class="position-relative" style="height: 100%">
        <mat-nav-list class="sidebar-list">
          <div *ngFor="let item of navItems">
            <!---->
            <app-nav-item *appHasPermission="item.permissions ?? []"
              [item]="item"
              (notify)="sidenav.toggle()"
            >
            </app-nav-item>
          </div>
        </mat-nav-list>
      </ng-scrollbar>
      <div class="env-label">
        <span>{{ "VERSION" | translate }} {{ versionLabel }} </span>
        <span *ngIf="!options.sidenavCollapsed"> - </span>
        <span class="chip chip-success" [ngClass]="{ 'chip-success': environmentLabel === 'DEV', 'chip-warning': environmentLabel === 'QA', 'chip-danger': environmentLabel === 'PROD' }">{{ environmentLabel }}</span>
      </div>
    </div>
  </mat-sidenav>

  <!-- ============================================================== -->
  <!-- horizontal Sidebar -->
  <!-- ============================================================== -->
  <mat-sidenav
      #leftsidenav
      [mode]="'over'"
      [opened]="options.sidenavOpened && !isTablet"
      *ngIf="options.horizontal && resView"
      (openedChange)="onSidenavOpenedChange($event)"
      (closedStart)="onSidenavClosedStart()"
      class="sidebarNav"
    >
    <app-sidebar> </app-sidebar>
    <ng-scrollbar class="position-relative" style="height: 100%">
      <mat-nav-list class="sidebar-list">
        <app-nav-item
          *ngFor="let item of navItems"
          [item]="item"
          (notify)="sidenav.toggle()"
        >
        </app-nav-item>
      </mat-nav-list>
    </ng-scrollbar>
  </mat-sidenav>
  <!-- ============================================================== -->
  <!-- Main Content -->
  <!-- ============================================================== -->
  <mat-sidenav-content class="contentWrapper" #content>
    <!-- ============================================================== -->
    <!-- VerticalHeader -->
    <!-- ============================================================== -->
    <ng-container *ngIf="!options.horizontal; else horizontalheader">
      <app-header
        [showToggle]="!isOver"
        (optionsChange)="receiveOptions($event)"
        (toggleCollapsed)="toggleCollapsed()"
        (toggleMobileNav)="sidenav.toggle()"
      ></app-header>
    </ng-container>

    <!-- horizontal header -->
    <ng-template #horizontalheader>
      <app-horizontal-header
        (toggleMobileNav)="sidenav.toggle()"
      ></app-horizontal-header>
    </ng-template>

    <app-horizontal-sidebar *ngIf="options.horizontal"></app-horizontal-sidebar>

    <main
      class="pageWrapper"
      [ngClass]="{
        maxWidth: options.boxed
      }"
    >
      <app-breadcrumb></app-breadcrumb>
      <!-- ============================================================== -->
      <!-- Outlet -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!--
      <div class="customizerBtn">
        <button mat-fab color="primary" (click)="customizerRight.toggle()">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
      -->
    </main>
  </mat-sidenav-content>
  <!--
  <mat-sidenav #customizerRight mode="over" position="end">
    <app-customizer (optionsChange)="receiveOptions($event)"></app-customizer>
  </mat-sidenav>
  -->
</mat-sidenav-container>
