import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { Subscription } from "rxjs";

import { CoreService } from "src/app/shared/services/core.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { IAppSettings } from "src/app/app.config";

/**
 * BlankComponent is a component that handles the application's blank view.
 * It manages theme settings and checks the authentication session status.
 */
@Component({
    selector: "app-blank",
    templateUrl: "./blank.component.html",
    styleUrls: [],
})
export class BlankComponent implements OnInit, OnDestroy {
    /** Subscription object to manage observable subscriptions. */
    private subscription: Subscription = new Subscription();
    /** HTML element representing the root <html> element. */
    private htmlElement!: HTMLHtmlElement;

    /** Flag indicating whether the component is ready to render. */
    readyToRender = false;
    /** Application settings. */
    options!: IAppSettings;

    /** @ignore */
    constructor(private settings: CoreService, private authService: AuthService, private router: Router) {
        this.options = this.settings.getOptions();
        this.htmlElement = document.querySelector("html")!;
        this.receiveOptions(this.options);
    }

    /** @ignore */
    ngOnInit(): void {
        this.router.events.subscribe(() => {
            const currentUrl = this.router.url;
            if (currentUrl.includes("/authentication/error")) {
                this.readyToRender = true;
            }
        });
        this.subscription.add(this.authService.sessionReady$.subscribe(isReady => {
            this.readyToRender = isReady;
        }));
    }

    /** @ignore */
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * Receives and applies application settings.
     * @param options The application settings to apply.
     */
    receiveOptions(options: IAppSettings): void {
        this.options = options;
        this.toggleDarkTheme(options);
        this.settings.setOptions(options);
    }

    /**
     * Toggles the theme between dark and light based on the provided settings.
     * @param options The application settings containing the theme information.
     */
    toggleDarkTheme(options: IAppSettings) {
        if (options.theme === "dark") {
            this.htmlElement.classList.add("dark-theme");
            this.htmlElement.classList.remove("light-theme");
        } else {
            this.htmlElement.classList.remove("dark-theme");
            this.htmlElement.classList.add("light-theme");
        }
    }
}