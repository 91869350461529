<div class="bg-breadcrumb rounded p-y-20 p-x-24 m-b-20 overflow-hidden" *ngIf="pageInfo?.['urls'] && pageInfo?.['urls'].length">
  <div class="row">
    <div class="col-sm-12">
      <div class="d-flex align-items-center overflow-hidden f-s-16">
        <ng-template
          ngFor
          let-url
          [ngForOf]="pageInfo?.['urls']"
          let-last="last"
          let-i="index"
        >
          <li class="breadcrumb-item d-flex align-items-center" *ngIf="!last" [routerLink]="url.url">
            <a [routerLink]="url.url">{{ url.title | translate }}</a>
            <span *ngIf="i === 0" class="breadcrumb-separator d-flex align-items-center m-l-4">
              &gt;
            </span>
          </li>
          <li class="breadcrumb-item d-flex align-items-center active" *ngIf="last">{{ url.title | translate }}</li>
        </ng-template>
      </div>
    </div>
  </div>
</div>
