import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';

/**
 * Service that provides default labels for the Angular DateTime Picker component.
 * The labels are translated using the ngx-translate service.
 */
@Injectable()
export class DefaultIntlAdapter extends OwlDateTimeIntl {
    /** A label for the cancel button */
    override cancelBtnLabel= 'Cancel';
    /** A label for the set button */
    override setBtnLabel= 'Confirm';
    /** A label for the range 'from' in picker info */
    override rangeFromLabel= 'From';
    /** A label for the range 'to' in picker info */
    override rangeToLabel= 'To';

    /** @ignore */
    constructor(private translateService: TranslateService) {
        super();
        this.initTranslations();
        translateService.onLangChange.subscribe(() => {
            this.initTranslations();
        });
    }

    /**
     * Initializes the translations for the labels using the ngx-translate service.
     * This method fetches the translated text for each label and updates the properties.
     */
    private initTranslations(): void {
        this.translateService.get('CANCEL').subscribe((translation: string) => {
            this.cancelBtnLabel = translation;
        });
        this.translateService.get('CONFIRM').subscribe((translation: string) => {
            this.setBtnLabel = translation;
        });
        this.translateService.get('FROM').subscribe((translation: string) => {
            this.rangeFromLabel = translation;
        });
        this.translateService.get('TO').subscribe((translation: string) => {
            this.rangeToLabel = translation;
        });
    }

}