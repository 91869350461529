import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

import { AuthService as Auth0Service } from "@auth0/auth0-angular";
import { map, Observable, of, switchMap, take } from "rxjs";

import { AuthService } from "../services/auth.service";

/**
 * AuthGuard is used to protect routes and ensure that the user is authenticated.
 */
@Injectable({
	providedIn: "root",
})
export class AuthGuard {
	/** @ignore */
	constructor(private auth0Service: Auth0Service, private authService: AuthService, private router: Router) {}

	/**
	 * Determines whether a route can be activated based on the user's authentication status.
	 * @returns {Observable<boolean> | boolean} An observable that emits the user's authentication status, or a boolean value.
	 */
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		const url = window.location.href;
		const params = new URLSearchParams(url.split('?')[1]);
		const error = params.get('error');
		const errorDescription = params.get('error_description');
		if (error === 'invalid_request' && errorDescription?.includes('client requires organization membership')) return true;

		return this.auth0Service.isAuthenticated$.pipe(
			take(1),
			switchMap((isAuthenticated) => {
				if (!isAuthenticated) {
					/** Redirects the user to the login page if they are not authenticated. */
					this.auth0Service.loginWithRedirect({ appState: { target: state.url } });
					return of(false);
				}
	
				return this.authService.setSession().pipe(
					map(() => {
						const requiredPermission = this.getPermission(next);
						const userPermissions = this.authService.getPermissions();
	
						if (requiredPermission && userPermissions.includes(requiredPermission)) return true;
	
						// Redirect to error page if permission is not granted
						this.router.navigate(['/authentication/error'], {
							queryParams: { no_permission: true }
						});

						return false;
					})
				);
			})
		);
	}

	/**
     * Recursively fetches the permission from the current route or any loaded child routes.
     * @param route The current activated route snapshot.
     * @returns The permission string or undefined.
     */
    private getPermission(route: ActivatedRouteSnapshot): string | undefined {
        // First, try to get the permission from the current route's data
        if (route.data && route.data['permission']) {
            return route.data['permission'] as string;
        }

        // If there are children directly in the snapshot, check them
        if (route.children && route.children.length > 0) {
            for (const child of route.children) {
                const permission = this.getPermission(child);
                if (permission) {
                    return permission;
                }
            }
        }

        return undefined; // No permission found
    }
}
