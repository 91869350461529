import { inject } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { ECodeError, IError } from "../interfaces/error.interface";

/**
 * Abstract class to be used as a base for other services, providing generic information and facilitating development and organization.
 */
export abstract class BaseService {
	/** TranslateService instance for internationalization */
	private translateService = inject(TranslateService);

	/**
	 * Generic method to handle errors in services.
	 * @param {(HttpErrorResponse | object)} error - The error object received from an HTTP request.
	 * @returns {Observable<never>} - Observable that throws an error with a user-friendly message.
	 */
	protected serviceError(error: HttpErrorResponse | object): Observable<never> {
		let errorMessage: string;

		if (error instanceof HttpErrorResponse) {
			if (error.status === 403) errorMessage = "NOTIFICATION.YOU_DO_NOT_HAVE_PERMISSION";
			else {
				const err = error.error as IError;
				switch (err.code) {
					case ECodeError.MANDATORY_FIELD:
					case ECodeError.DUPLICATED_FIELD:
					case ECodeError.FIELD_TYPE:
					case ECodeError.DOCUMENT_INVALID:
					case ECodeError.REGISTER_DUPLICATED:
						errorMessage = this.getErrorMessage(err.code, err.message.property);
						break;
					default: {
						let message = error.message;
						if (error.error && error instanceof HttpErrorResponse && typeof error.error.message === 'string') message = error.error.message as string;
						errorMessage = `Server returned code: ${error.status}, error message is: ${message}`;
					}
				}
			}
		}

		// Check if the error message contains "invalid refresh token"
		// eslint-disable-next-line @typescript-eslint/no-base-to-string
		const errorString = error.toString();
		const isInvalidRefreshTokenError = errorString.includes("invalid refresh token");
		if (isInvalidRefreshTokenError) errorMessage = "NOTIFICATION.SESSION_EXPIRED";

		return throwError(() => errorMessage ? new Error(errorMessage) : error);
	}

	/**
	 * Get the translated error message based on the error code.
	 * @param {ECodeError} errorCode - The error code to translate.
	 * @param {string} property - The property associated with the error.
	 * @returns {string} - The translated error message.
	 */
	private getErrorMessage(errorCode: ECodeError, property: string): string {
		const errorMessages = {
			[ECodeError.MANDATORY_FIELD]: "NOTIFICATION.MANDATORY_FIELD",
			[ECodeError.DUPLICATED_FIELD]: "NOTIFICATION.DUPLICATED_FIELD",
			[ECodeError.FIELD_TYPE]: "NOTIFICATION.FIELD_TYPE",
			[ECodeError.DOCUMENT_INVALID]: "NOTIFICATION.INVALID_FIELD",
			[ECodeError.REGISTER_DUPLICATED]: "NOTIFICATION.REGISTER_DUPLICATED",
		};
		return this.translateService.instant(errorMessages[errorCode], { field: property }) as string;
	}
}