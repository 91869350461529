import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import { Observable, catchError, map } from "rxjs";

import { environment } from "src/environments/environment";
import { CrudBaseService } from "../../base/crud-base.service";
import { IGridFilter, IGridFilterListResponse } from "../../interfaces/grid-filter.interface";

/** Responsible for making the grid-filter manipulation methods available by the main api */
@Injectable()
export class GridFilterService extends CrudBaseService<IGridFilter> {
    /** @ignore */
    constructor(protected override readonly http: HttpClient) {
        super(http, environment.mainApiUrl, "gridFilter");
    }

    /**
     * Returns a list of filters of requested grid
     * @param {EGridFilterGrid} grid - Grid name that will be requested
     * @returns Observable<T>
     */
    listGridFilter(grid: string): Observable<IGridFilterListResponse[]> {
        return this.http
            .get(`${this.apiUrl}/` + grid)
            .pipe(
                map((data) => data as IGridFilterListResponse[]),
                catchError((err: HttpErrorResponse) => this.serviceError(err))
            );
    }

    /**
     * Set used property of grid filter
     * @param {string} id - Id of grid filter that will be updated
     * @param {boolean} used - Value that indicate if will be used
     * @returns Observable<boolean>
     */
    used(id: string, used: boolean): Observable<boolean> {
        return this.http
            .patch(`${this.apiUrl}/used/` + id, { used })
            .pipe(
                map((success) => success as boolean),
                catchError((err: HttpErrorResponse) => this.serviceError(err))
            );
    }

    /**
     * Favorite/Unfavorite grid filter
     * @param {string} id - Id of grid filter that will be updated
     * @param {boolean} favorite - Value that indicate if will be favorited or unfavorited
     * @returns Observable<boolean>
     */
    favorite(id: string, favorite: boolean): Observable<boolean> {
        return this.http
            .patch(`${this.apiUrl}/favorite/` + id, { favorite })
            .pipe(
                map((success) => success as boolean),
                catchError((err: HttpErrorResponse) => this.serviceError(err))
            );
    }

    /**
     * Remove filter
     * @param {string} id - Item id that will be removed
     * @returns boolean that represents if the remove was successful
     */
    remove(id: string): Observable<boolean> {
        return this.http.delete(this.apiUrl + "/" + id).pipe(
            map(() => true),
            catchError((err: HttpErrorResponse) => this.serviceError(err))
        );
    }

}