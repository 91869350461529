import { Injectable } from "@angular/core";

import { ToastrService, ActiveToast } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

/** Responsible for providing the generic notification manipulation methods */
@Injectable({
    providedIn: "root",
})
export class NotificationService {
    /** Success title of notification box translated */
    titleSuccess = "NOTIFICATION.TITLE_SUCCESS";
    /** Info title of notification box translated */
    titleInfo = "NOTIFICATION.TITLE_INFO";
    /** Warning title of notification box translated */
    titleWarning = "NOTIFICATION.TITLE_WARNING";
    /** Error title of notification box translated */
    titleError = "NOTIFICATION.TITLE_ERROR";
    /** Message default of success */
    msgSuccessDefault = "NOTIFICATION.MSG_SUCCESS";
    /** Message default of error */
    msgErrorDefault = "NOTIFICATION.MSG_ERROR";

    /** @ignore */
    constructor(
        private translateService: TranslateService,
        private toastrService: ToastrService
    ) {}

    /** Notify success msg(green) */
    notifySuccess(
        msg?: string,
        concatWithDefaultMsg?: boolean,
        translateParams?: Record<any, unknown> | undefined
    ): ActiveToast<any> {
        let notifyMsg = msg
            ? (this.translateService.instant(msg, translateParams) as string)
            : (this.translateService.instant(this.msgSuccessDefault) as string);
        if (concatWithDefaultMsg && msg)
            notifyMsg = (
                this.translateService.instant(this.msgSuccessDefault) as string
            ).concat(` ${msg}`);
        return this.toastrService.success(
            notifyMsg,
            this.translateService.instant(this.titleSuccess) as string
        );
    }

    /** Notify info msg(blue) */
    notifyInfo(
        msg: string,
        translateParams?: Record<any, unknown> | undefined
    ): ActiveToast<any> {
        return this.toastrService.info(
            this.translateService.instant(msg, translateParams) as string,
            this.translateService.instant(this.titleInfo) as string
        );
    }

    /** Notify warning msg(orange) */
    notifyWarning(
        msg: string,
        translateParams?: Record<any, unknown> | undefined
    ): ActiveToast<any> {
        return this.toastrService.warning(
            this.translateService.instant(msg, translateParams) as string,
            this.translateService.instant(this.titleWarning) as string
        );
    }

    /** Notify error msg(red) */
    notifyError(
        msg?: string,
        concatWithDefaultMsg?: boolean,
        translateParams?: Record<any, unknown> | undefined
    ): ActiveToast<any> {
        let notifyMsg = msg
            ? (this.translateService.instant(msg, translateParams) as string)
            : (this.translateService.instant(this.msgErrorDefault) as string);
        if (concatWithDefaultMsg && msg)
            notifyMsg = (
                this.translateService.instant(this.msgErrorDefault) as string
            ).concat(`: ${msg}`);
        return this.toastrService.error(
            notifyMsg,
            this.translateService.instant(this.titleError) as string
        );
    }
}
