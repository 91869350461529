export const environment = {
	production: true,
	environmentName: "QA",
	frontUrl: "https://crm-qa.allog.app/",
	mainApiUrl: "https://main.crm-qa.allog.app/",
	monacoUrl: "https://newmonacoqa.allog.com.br/#allog/app/",
	auth: {
		domain: "allog-prod.us.auth0.com",
		clientId: "PbPP2JZHhvsuNOVvjjw5xSscFyKFjx99",
		redirectUri: window.location.origin,
		audience: "https://main.crm.allog.app",
	},
};
