import { Injectable } from "@angular/core";

import { NativeDateTimeAdapter } from "@danielmoncada/angular-datetime-picker";

import * as moment from "moment";

/**
 * Custom date adapter extending NativeDateTimeAdapter to format dates using moment.js.
 * @export
 * @class CustomDateAdapter
 * @extends {NativeDateTimeAdapter}
 */
@Injectable()
export class CustomDateAdapter extends NativeDateTimeAdapter {

    /**
     * Overrides the format method to format the date using moment.js.
     * @param {Date} date - The date to format.
     * @param {string} displayFormat - The format string.
     * @return {string} The formatted date string.
     * @memberof CustomDateAdapterNgx
     */
    override format(date: Date, displayFormat: string): string {
        return moment(date).format(displayFormat.toString());
    }
}