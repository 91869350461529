import { Provider } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { ErrorHandlerInterceptor } from "../interceptors/error-handler.interceptor";

/** Error interceptor provider to use in module */
export const ErrorHandlerProvider: Provider = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorHandlerInterceptor,
        multi: true,
    },
];
