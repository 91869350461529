import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";

import { NgxSpinnerService } from "ngx-spinner";

import { environment as env } from "src/environments/environment";

/**
 * Invite callback component used to handle with auth0 invite redirect.
 */
@Component({
	selector: "app-invite-callback",
	template: `
	<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
		<span style="color: white">{{ 'REDIRECTING' | translate }}...</span>
	</ngx-spinner>
	`,
})
export class InviteCallbackComponent implements OnInit {
	/** @ignore */
	constructor(private route: ActivatedRoute, private authService: AuthService, private spinnerService: NgxSpinnerService) { }

	/** @ignore */
	ngOnInit(): void {
		this.spinnerService.show();
		this.route.queryParams.subscribe((params) => {
			const invitation = params["invitation"] as string;
			const organization = params["organization"] as string;
			const issuer = params["iss"] as string;

			if (invitation && organization) {
				const redirectUri = encodeURIComponent(
					window.location.origin + "/callback"
				);

				const auth0AuthorizeUrl = `https://${env.auth.domain}/authorize?client_id=${env.auth.clientId}&response_type=code&scope=openid%20profile%20email&redirect_uri=${redirectUri}&invitation=${invitation}&organization=${organization}`;

				window.location.href = auth0AuthorizeUrl;
			} else if (issuer) this.authService.loginWithRedirect();
			else console.error("Parâmetros de convite ou organização ausentes.");
		});
	}
}
