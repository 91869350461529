import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import { IAppSettings, defaults } from "../../app.config";

/**
 * Core service for managing application settings and providing notifications.
 * @export
 * @class CoreService
 */
@Injectable({
	providedIn: "root",
})
export class CoreService {

    /** @ignore **/
	private options: IAppSettings = defaults;
	/** @ignore **/
	private notify$ = new BehaviorSubject<IAppSettings | Record<string, any>>({});

    /**
	 * Observable for notifying changes in application settings.
	 * @readonly
	 * @type {Observable<IAppSettings | Record<string, any>>}
	 * @memberof CoreService
	 */
	get notify(): Observable<IAppSettings | Record<string, any>> {
		return this.notify$.asObservable();
	}

	/**
	 * Retrieves the current application settings.
	 * @return {IAppSettings} The current application settings.
	 * @memberof CoreService
	 */
	getOptions(): IAppSettings {
		return this.options;
	}

	/**
	 * Updates the application settings and notifies subscribers.
	 * @param {IAppSettings} options - The new application settings.
	 * @memberof CoreService
	 */
	setOptions(options: IAppSettings): void {
		this.options = Object.assign(defaults, options);
		this.notify$.next(this.options);
	}

	/**
	 * Retrieves the current language setting.
	 * @return {string} The current language.
	 * @memberof CoreService
	 */
	getLanguage(): string {
		return this.options.language;
	}

	/**
	 * Updates the current language setting and notifies subscribers.
	 * @param {string} lang - The new language.
	 * @memberof CoreService
	 */
	setLanguage(lang: string): void {
		this.options.language = lang;
		this.notify$.next({ lang });
	}
}