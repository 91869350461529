import { ISelectorModel } from "./shared/interfaces/selector.interface";

export interface IAppSettings {
  dir: 'ltr' | 'rtl';
  theme: string;
  sidenavOpened: boolean;
  sidenavCollapsed: boolean;
  boxed: boolean;
  horizontal: boolean;
  activeTheme: string;
  language: string;
  accessGroup: ISelectorModel | null;
  physicalPersonId: string;
  cardBorder: boolean;
  navPos: 'side' | 'top';
}

export const defaults: IAppSettings = {
  dir: 'ltr',
  theme: 'light',
  sidenavOpened: false,
  sidenavCollapsed: false,
  boxed: false,
  horizontal: false,
  cardBorder: false,
  activeTheme: 'blue_theme',
  language: 'en',
  accessGroup: null,
  physicalPersonId: "",
  navPos: 'side',
};
