import { Component, ChangeDetectorRef } from "@angular/core";
import { CommonModule, NgForOf, NgIf } from "@angular/common";
import { Router } from "@angular/router";
import { MediaMatcher } from "@angular/cdk/layout";

import { navItems } from "./sidebar-data";
import { NavService } from "../../../../shared/services/nav.service";
import { HorizontalNavItemComponent } from "./nav-item/nav-item.component";

/**
 * HorizontalSidebarComponent is a standalone component that displays a horizontal sidebar navigation.
 */
@Component({
    selector: "app-horizontal-sidebar",
    standalone: true,
    imports: [HorizontalNavItemComponent, NgIf, NgForOf, CommonModule],
    templateUrl: "./sidebar.component.html",
})
export class HorizontalSidebarComponent {
    /**
     * List of navigation items to be displayed in the sidebar.
     */
    navItems = navItems;

    /**
     * MediaQueryList to check if the screen width is at least 1100px.
     */
    mobileQuery: MediaQueryList;
    
    /** @ignore */
    private _mobileQueryListener: () => void;

    /** @ignore */
    constructor(
        public navService: NavService,
        public router: Router,
        media: MediaMatcher,
        changeDetectorRef: ChangeDetectorRef
    ) {
        this.mobileQuery = media.matchMedia("(min-width: 1100px)");
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
    }
}