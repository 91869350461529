import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpErrorResponse,
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "@auth0/auth0-angular";

/** Responsable for deal with the interceptor of errors*/
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
	/** @ignore */
	private router = inject(Router);
	/** @ignore */
	private authService = inject(AuthService);

	/** @ignore */
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse | object) => {
				let isInvalidRefreshTokenError = false;
				if (error instanceof HttpErrorResponse) {
					this.redirect(error, isInvalidRefreshTokenError);
				} else {
					// eslint-disable-next-line @typescript-eslint/no-base-to-string
					const errorString = error.toString();
					isInvalidRefreshTokenError = errorString.includes("invalid refresh token");
					if (isInvalidRefreshTokenError) this.redirect(null, isInvalidRefreshTokenError);
				}
				return throwError(() => error as HttpErrorResponse);
			})
		);
	}

	/** Responsible for redirects according to the conditions */
	private redirect(error: HttpErrorResponse | null, isInvalidRefreshTokenError?: boolean) {
		if (isInvalidRefreshTokenError/* || error && (error.status === 403 || error.status === 401)*/) {
			this.authService.logout();
		} else if (
			error &&
			error.name === "HttpErrorResponse" &&
			error.status !== 400 &&
			error.status !== 403 &&
			error.status !== 404 &&
			error.status !== 500 &&
			error.status !== 0
		) {
			this.router.navigate(["/error"]);
		}
	}
}
