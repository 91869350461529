import { TranslateLoader } from "@ngx-translate/core";

import { Observable, of } from "rxjs";

import translationsEN from "src/assets/i18n/en.json";
import translationsPT from "src/assets/i18n/pt.json";
import { ETranslateLang } from "../../interfaces/i18n.interface";

/** Role responsible for building the TranslationHttpLoader
 * @returns TranslationHttpLoader
 */
export function HttpLoaderFactory() {
    return new TranslationHttpLoader();
}

/** i18n translation loader - responsable to config the search of terms */
export class TranslationHttpLoader implements TranslateLoader {
    /** Method responsable to config the i18n search of term*/
    public getTranslation(lang: ETranslateLang): Observable<any> {
        let translationsForLang = translationsEN;
        if ((lang && lang === ETranslateLang.PT) || (lang === null && navigator.language === "pt-BR")) translationsForLang = translationsPT;

        return of(translationsForLang);
    }
}
