import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { NgxSpinnerService } from "ngx-spinner";

import { AuthService } from "src/app/shared/services/auth.service";

/**
 * Auth0 callback component used to handle with auth0 authentication after redirect.
 */
@Component({
	selector: "app-auth-callback",
	template: `
		<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-multiple" [fullScreen]="true">
			<span style="color: white">{{ 'LOADING' | translate }}...</span>
		</ngx-spinner>
	`,
})
export class AuthCallbackComponent implements OnInit {
	/** @ignore */
	constructor(private authService: AuthService, private router: Router, private spinnerService: NgxSpinnerService) {}

	/** @ignore */
	ngOnInit(): void {
		this.spinnerService.show();
		this.authService.handleAuthentication().subscribe({
			next: (result) => {
				this.spinnerService.hide();
				const targetUrl = result?.appState?.target as string || "/";
				this.router.navigate([targetUrl]);
			},
			error: () => {
				this.spinnerService.hide();
				this.router.navigate(["/authentication/error"]);
			},
		});
	}
}
