import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { TranslateService } from "@ngx-translate/core";
import { interval, Observable, of } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";

/** @ignore */
interface IVersionInfo {
    version: string;
    date: string;
}

/**
 * Service responsible for managing the application's version control.
 * Checks for new versions periodically and prompts the user for a refresh if a new version is detected.
 */
@Injectable({
	providedIn: "root",
})
export class VersionCheckService {
    /** @ignore */
	private versionFile = "/assets/version.json";
    /** @ignore */
	private currentVersion: string | null = null;

    /** @ignore */
	constructor(private http: HttpClient, private translateService: TranslateService) {}

    /**
     * Fetches the current application version from the version file.
     * @returns Observable containing version information.
     */
    public getVersion(): Observable<IVersionInfo> {
        return this.http.get<IVersionInfo>(this.versionFile);
    }

    /**
     * Starts the version checking process.
     * This process will periodically check for updates every specified interval.
     * @param intervalMs Polling interval in milliseconds (default: 60000 ms).
     */
    public initVersionCheck(intervalMs: number = 60000): void {
        this.loadCurrentVersion().subscribe({
            next: (initialVersion) => {
                this.currentVersion = initialVersion;
                this.startVersionPolling(intervalMs);
            },
            error: (error) => console.error("Error fetching initial version:", error),
        });
    }

    /**
     * Loads the current version of the application.
     * @returns Observable containing the current version string.
     */
    private loadCurrentVersion(): Observable<string> {
        return this.getVersion().pipe(
            catchError((error) => {
                console.error("Error fetching version:", error);
                return of({ version: "unknown" });
            }),
            switchMap((response) => of(response.version))
        );
    }

    /**
     * Starts polling the version file at the specified interval.
     * @param intervalMs Polling interval in milliseconds.
     */
    private startVersionPolling(intervalMs: number): void {
        interval(intervalMs)
            .pipe(
                switchMap(() => this.getVersion()),
                tap((response) => this.verifyVersion(response.version))
            )
            .subscribe({
                error: (error) => console.error("Error fetching version during polling:", error),
            });
    }

    /**
     * Verifies if the new version differs from the current version.
     * If a new version is detected, it triggers an update prompt for the user.
     * @param newVersion The new version string fetched from the version file.
     */
    private verifyVersion(newVersion: string): void {
        if (this.currentVersion && this.currentVersion !== newVersion) {
            this.promptUserForUpdate();
        }
    }

    /**
     * Prompts the user to refresh the application if a new version is available.
     * If confirmed, the page reloads to load the new version.
     */
    private promptUserForUpdate(): void {
        if (confirm(this.translateService.instant("NOTIFICATION.NEW_VERSION_AVAILABLE") as string)) {
            window.location.reload();
        }
    }
}