import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { NgForOf } from "@angular/common";

import { TablerIconsModule } from "angular-tabler-icons";

import { MaterialModule } from "src/app/material.module";
import { navItems } from "src/app/layouts/full/vertical/sidebar/sidebar-data";

/**
 * AppVerticalSearchDialogComponent is a standalone component that represents a search dialog.
 * It allows filtering of navigation items based on the search text entered by the user.
 */
@Component({
	selector: "app-vertical-search-dialog",
	standalone: true,
	imports: [
		RouterModule,
		MaterialModule,
		TablerIconsModule,
		FormsModule,
		NgForOf,
	],
	templateUrl: "search-dialog.component.html",
})
export class AppVerticalSearchDialogComponent {
	/** The text entered by the user for searching navigation items. */
	searchText: string = "";
	/** The list of navigation items. */
	navItems = navItems;

	/**
	 * Filters the navigation items based on the search text.
	 * @returns A filtered list of navigation items that match the search text.
	 */
	get filteredNavItems() {
		return this.navItems.filter((item) =>
			item?.displayName
				?.toLowerCase()
				.includes(this.searchText.toLowerCase())
		);
	}
}
