import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Provider } from "@angular/core";

import { TokenInterceptor } from "../interceptors/token.interceptor";

/** Token interceptor provider to use in module */
export const TokenInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
};
