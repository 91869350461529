import { environment } from "src/environments/environment";
import { INavItem } from "../../vertical/sidebar/nav-item/nav-item";

/** @ignore */
export const navItems: INavItem[] = [
	{
        displayName: "MENU.SAMPLE",
        iconName: "grid-dots",
        route: "/samples/grid",
        permissions: ["crm-samples-view"],
        cypressId: "gridSamplesNav",
    },
    {
		displayName: "MENU.USERS",
		iconName: "users",
		route: "/registers/user",
		permissions: ["crm-users-view"],
		cypressId: "gridUserNav",
	},
    {
		displayName: "MENU.ACCESS_GROUP",
		iconName: "lock-access",
		route: "/registers/accessGroup",
		permissions: ["crm-accessGroups-view"],
		cypressId: "gridAccessGroupNav",
	},
    {
        displayName: 'MENU.LEAD',
        iconName: 'user-question',
        route: '/registers/leads',
        permissions: ['crm-leads-view'],
        cypressId: 'gridLeadsNav'
    },
    {
        displayName: 'MENU.COMMERCIAL_ACTION',
        iconName: 'file-invoice',
        route: '/registers/commercialActions',
        permissions: ['crm-commercialActions-view'],
        cypressId: 'gridCommercialActionsNav'
    },
    {
		displayName: "MENU.MONACO",
		iconName: "circle-arrow-left",
		action: () => window.open(environment.monacoUrl + "home", "_blank"),
		permissions: [],
		cypressId: "monacoNav",
	}
];
