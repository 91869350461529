import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { environment as env } from "src/environments/environment";
//Auth0
import { provideAuth0 } from "@auth0/auth0-angular";
import { TokenInterceptorProvider } from "./shared/providers/token-interceptor.provider";
// Error handler interceptor
import { ErrorHandlerProvider } from "./shared/providers/error-handler.provider";
// perfect scrollbar
import { NgScrollbarModule } from "ngx-scrollbar";
// Ngx toastr - notifications
import { ToastrModule } from "ngx-toastr";
//Import Spinner
import { NgxSpinnerModule } from "ngx-spinner";
// Translate
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
// icons
import { TablerIconsModule } from "angular-tabler-icons";
import * as TablerIcons from "angular-tabler-icons/icons";
//Ag grid license
import "./shared/providers/ag-grid-license.provider";
//Import all material modules
import { MaterialModule } from "./material.module";
//Import Layouts
import { FullComponent } from "./layouts/full/full.component";
import { BlankComponent } from "./layouts/blank/blank.component";
//Import Shared Module
import { SharedModule } from "./shared/shared.module";
//Import Auth0 callback components
import { InviteCallbackComponent } from "./modules/pages/authentication/invite-callback/invite-callback.component";
import { AuthCallbackComponent } from "./modules/pages/authentication/auth-callback/auth-callback.component";
//i18n http loader factory
import { HttpLoaderFactory } from "./shared/services/i18n/translate.http.loader";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

/** Translate default language */
const defaultLang = "en";

/** Initializer factory - Initializes the translation on start  */
export function appInitializerFactory(translate: TranslateService) {
    return () => {
        translate.setDefaultLang(defaultLang);
        return translate.use(defaultLang);
    };
}

@NgModule({
	declarations: [
		AppComponent,
		BlankComponent,
		InviteCallbackComponent,
		AuthCallbackComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		BrowserAnimationsModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		SharedModule,
		TablerIconsModule.pick(TablerIcons),
		NgScrollbarModule,
		NgxSpinnerModule,
		ToastrModule.forRoot({ positionClass: "toast-top-right" }),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
			//defaultLanguage: defaultLang
		}),
		FullComponent,
	],
	exports: [TablerIconsModule],
	bootstrap: [AppComponent],
	providers: [
		{
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService],
            multi: true,
        },
		provideAuth0({
			domain: env.auth.domain,
			clientId: env.auth.clientId,
			useRefreshTokens: true,
			cacheLocation: "localstorage",
			authorizationParams: {
				redirect_uri: window.location.origin,
				audience: env.auth.audience,
			},
		}),
		ErrorHandlerProvider,
		TokenInterceptorProvider
	],
})
export class AppModule {}
