<mat-toolbar class="topbar horizontal-topbar">
	<div class="container">
		<div class="d-none d-sm-flex">
			<app-branding></app-branding>
		</div>

		<!-- Mobile Menu -->
		<button
			mat-icon-button
			(click)="toggleMobileNav.emit()"
			class="d-block d-lg-none">
			<i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
		</button>
		<!-- --------------------------------------------------------------- -->

		<!-- --------------------------------------------------------------- -->
		<!--  Search -->
		<!-- --------------------------------------------------------------- -->
		<!--
		<button mat-icon-button (click)="openDialog()" class="d-flex">
			<i-tabler name="search" class="icon-20 d-flex"></i-tabler>
		</button>
		-->

		<div class="d-inline-flex">
			<button
				*ngIf="options.theme === 'light'"
				mat-icon-button
				(click)="setDark()"
				class="d-flex justify-content-center">
				<i-tabler name="sun-high" class="icon-20 d-flex"></i-tabler>
			</button>
			<button
				*ngIf="options.theme === 'dark'"
				mat-icon-button
				(click)="setLight()"
				class="d-flex justify-content-center">
				<i-tabler name="moon" class="icon-20 d-flex"></i-tabler>
			</button>
			<button
				mat-icon-button
				(click)="openCurrentPageInNewTab()"
				class="d-flex justify-content-center">
				<i-tabler name="external-link" class="icon-20 d-flex"></i-tabler>
			</button>
		</div>

		<span class="flex-1-auto"></span>

		<!-- --------------------------------------------------------------- -->
		<!-- langugage Dropdown -->
		<!-- --------------------------------------------------------------- -->
		<button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5">
			<img
				alt="Language img"
				[src]="selectedLanguage.icon"
				class="rounded-circle object-cover icon-20" />
		</button>
		<mat-menu #flags="matMenu" class="cardWithShadow">
			@for(lang of languages; track lang.icon) {
			<button mat-menu-item (click)="changeLanguage(lang)">
				<div class="d-flex align-items-center">
					<img
						alt="Country flag img"
						[src]="lang.icon"
						class="rounded-circle object-cover m-r-8 icon-20" />
					<span class="mat-subtitle-1 f-s-14">{{
						lang.language
					}}</span>
				</div>
			</button>
			}
		</mat-menu>
		<!-- --------------------------------------------------------------- -->
		<!-- profile Dropdown -->
		<!-- --------------------------------------------------------------- -->
		<button *ngIf="authService.user$ | async as user"
			mat-icon-button
			[matMenuTriggerFor]="profilemenu"
			aria-label="Notifications">
			<img
				*ngIf="user.picture; else initials"
				[src]="user.picture"
				alt="Profile user img"
				class="rounded-circle"
				width="35"
			/>
			<ng-template #initials>
				<div class="profile-initials rounded-circle" style="width: 95px; height: 95px; display: flex; align-items: center; justify-content: center; background-color: #ccc; color: #fff; font-size: 40px;">
					{{ user.name?.charAt(0) }}
				</div>
			</ng-template>
		</button>
		<mat-menu
			#profilemenu="matMenu"
			xPosition="before"
			class="topbar-dd cardWithShadow">
			<div class="p-x-32 p-y-16">
				<h6 class="f-s-16 f-w-600 m-0 mat-subtitle-1">{{ 'USER_PROFILE' | translate }}</h6>

				<div class="d-flex align-items-center p-b-24 b-b-1 m-t-16" *ngIf="authService.user$ | async as user">
					<img
                        *ngIf="user.picture; else initials"
                        [src]="user.picture"
                        alt="Profile user img"
                        class="rounded-circle"
                        width="65"
                    />
                    <ng-template #initials>
                        <div class="profile-initials rounded-circle" style="width: 95px; height: 95px; display: flex; align-items: center; justify-content: center; background-color: #ccc; color: #fff; font-size: 40px;">
                            {{ user.name?.charAt(0) }}
                        </div>
                    </ng-template>
					<div class="m-l-16">
						<h6 class="f-s-14 f-w-600 m-0 mat-subtitle-1">{{ user.name }}</h6>
						<span class="d-flex align-items-center">
							<i-tabler name="mail" class="icon-15 m-r-4"></i-tabler>
							<span class="d-block text-truncate" [matTooltip]="user.email ?? ''" style="max-width: 165px;">{{ user.email }}</span>
						</span>
					</div>
				</div>
			</div>
			<div class="p-y-12 p-x-32">
				<a
					tabindex="0"
					(click)="authService.logout()"
					(keyup.enter)="authService.logout()"
					mat-stroked-button
					color="primary"
					class="w-100"
					>{{ 'LOGOUT' | translate }}</a
				>
			</div>
		</mat-menu>
	</div>
</mat-toolbar>
