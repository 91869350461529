import { BreakpointObserver, MediaMatcher } from "@angular/cdk/layout";
import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { RouterModule, NavigationEnd, Router } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MatSidenav, MatSidenavContent } from "@angular/material/sidenav";

import { TranslateModule } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgScrollbarModule } from "ngx-scrollbar";
import { TablerIconsModule } from "angular-tabler-icons";

import { CoreService } from "src/app/shared/services/core.service";
import { IAppSettings } from "src/app/app.config";
import { SharedModule } from "src/app/shared/shared.module";
import { MaterialModule } from "src/app/material.module";
import { AuthService } from "src/app/shared/services/auth.service";
import { UserSettingsService } from "src/app/shared/services/main/user-settings.service";
import { VersionCheckService } from "src/app/shared/services/version-check.service";
import { environment } from "src/environments/environment";
import { navItems } from "./vertical/sidebar/sidebar-data";
import { NavService } from "../../shared/services/nav.service";
import { AppNavItemComponent } from "./vertical/sidebar/nav-item/nav-item.component";
import { SidebarComponent } from "./vertical/sidebar/sidebar.component";
import { HeaderComponent } from "./vertical/header/header.component";
import { HorizontalHeaderComponent } from "./horizontal/header/header.component";
import { HorizontalSidebarComponent } from "./horizontal/sidebar/sidebar.component";

/** @ignore */
const MOBILE_VIEW = "screen and (max-width: 768px)";
/** @ignore */
const TABLET_VIEW = "screen and (min-width: 769px) and (max-width: 1024px)";
/** @ignore */
const MONITOR_VIEW = "screen and (min-width: 1024px)";
/** @ignore */
const BELOWMONITOR = "screen and (max-width: 1023px)";

/**
 * FullComponent is a standalone component that manages the layout and navigation of the application.
 */
@Component({
    selector: "app-full",
    standalone: true,
    imports: [
        SharedModule,
        RouterModule,
        AppNavItemComponent,
        MaterialModule,
        CommonModule,
        TranslateModule,
        SidebarComponent,
        NgScrollbarModule,
        TablerIconsModule,
        HeaderComponent,
        HorizontalHeaderComponent,
        HorizontalSidebarComponent,
    ],
    templateUrl: "./full.component.html",
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})
export class FullComponent implements OnInit, OnDestroy {
    /** Version label */
    versionLabel: string = '';
    /** Environment label */
    environmentLabel: string = '';
    /** Indicates if the component is ready to render. */
    readyToRender = false;
    /** Indicates if the view is responsive. */
    resView = false;
    /** List of navigation items. */
    navItems = navItems;
    /** Application settings. */
    options!: IAppSettings;
    
    /** Reference to the left sidenav component. */
    @ViewChild("leftsidenav") public sidenav!: MatSidenav;
    /** Reference to the content of the sidenav. */
    @ViewChild("content", { static: true }) content!: MatSidenavContent;

    /** @ignore */
    private subscription: Subscription = new Subscription();
    /** @ignore */
    private layoutChangesSubscription = Subscription.EMPTY;
    /** @ignore */
    private isMobileScreen = false;
    /** @ignore */
    private isContentWidthFixed = true;
    /** @ignore */
    private isCollapsedWidthFixed = false;
    /** @ignore */
    private htmlElement!: HTMLHtmlElement;
    /** @ignore */
    private updateUserSettingsSubject: Subject<void> = new Subject<void>();

    /** Indicates if the screen is in mobile view. */
    get isOver(): boolean {
        return this.isMobileScreen;
    }

    /** Indicates if the screen is in tablet view. */
    get isTablet(): boolean {
        return this.resView;
    }

    /** @ignore */
    constructor(
        private settings: CoreService,
        private mediaMatcher: MediaMatcher,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private navService: NavService,
        private authService: AuthService,
        private userSettingsService: UserSettingsService,
        private versionCheckService: VersionCheckService
    ) {
        this.options = this.settings.getOptions();
        this.htmlElement = document.querySelector("html")!;
        this.layoutChangesSubscription = this.breakpointObserver
            .observe([MOBILE_VIEW, TABLET_VIEW, MONITOR_VIEW, BELOWMONITOR])
            .subscribe((state) => {
                this.options.sidenavOpened = true;
                this.isMobileScreen = state.breakpoints[MOBILE_VIEW];
                if (!this.options.sidenavCollapsed) {
                    this.options.sidenavCollapsed =
                        state.breakpoints[TABLET_VIEW];
                }
                this.isContentWidthFixed = state.breakpoints[MONITOR_VIEW];
                this.resView = state.breakpoints[BELOWMONITOR];
            });

        this.userSettingsService.list().subscribe({
            next: (userSettings) => {
                this.options.language = userSettings.language;
                this.options.accessGroup = userSettings.accessGroup;
                this.options.physicalPersonId = userSettings.physicalPersonId;
                this.options.theme = userSettings.theme;
                this.options.sidenavCollapsed = userSettings.menuCollapsed;
            },
            error: () => false,
            complete: () => this.receiveOptions(this.options)
        });

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.content?.scrollTo({ top: 0 });
            });
    }

    /** @ignore */
    ngOnInit(): void {
        this.setVersionLabel();
        this.setEnvironmentLabel();
        this.toggleDarkTheme(this.options);
        this.subscription.add(this.authService.sessionReady$.subscribe(isReady => {
            this.readyToRender = isReady;
        }));
        this.initUpdateUserSettings();
    }

    /** Lifecycle hook that is called when the component is destroyed. */
    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.layoutChangesSubscription.unsubscribe();
    }

    /** Toggles the collapsed state of the sidenav. */
    toggleCollapsed() {
        this.isContentWidthFixed = false;
        this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
        this.updateUserSettingsSubject.next();
        this.resetCollapsedState();
    }

    /**
     * Resets the collapsed state of the sidenav after a specified delay.
     * @param timer Delay in milliseconds before resetting the state.
     */
    resetCollapsedState(timer = 400) {
        setTimeout(() => this.settings.setOptions(this.options), timer);
    }

    /** Handler for the start of sidenav close animation. */
    onSidenavClosedStart() {
        this.isContentWidthFixed = false;
    }

    /**
     * Handler for the change in sidenav opened state.
     * @param isOpened Indicates if the sidenav is opened.
     */
    onSidenavOpenedChange(isOpened: boolean) {
        this.isCollapsedWidthFixed = !this.isOver;
        this.options.sidenavOpened = isOpened;
        this.settings.setOptions(this.options);
    }

    /**
     * Receives and applies new application settings.
     * @param options New application settings.
     */
    receiveOptions(options: IAppSettings): void {
        this.options = options;
        this.toggleDarkTheme(options);
        this.settings.setOptions(options);
    }

    /**
     * Toggles the dark theme based on application settings.
     * @param options Application settings.
     */
    toggleDarkTheme(options: IAppSettings) {
        if (options.theme === "dark") {
            this.htmlElement.classList.add("dark-theme");
            this.htmlElement.classList.remove("light-theme");
        } else {
            this.htmlElement.classList.remove("dark-theme");
            this.htmlElement.classList.add("light-theme");
        }
    }

    /** Initializes the update of user settings with a debounced subject. */
    private initUpdateUserSettings() {
        this.updateUserSettingsSubject.subscribe(() => {
            this.userSettingsService.update({ menuCollapsed: this.options.sidenavCollapsed }).subscribe();
        });
    }

    /** Initializes version label */
    private setVersionLabel() {
        this.versionCheckService.getVersion().subscribe((response) => {
            this.versionLabel = response.version;
        });
    }

    /** Initializes environment label */
    private setEnvironmentLabel() {
        this.environmentLabel = environment.environmentName;
    }
}