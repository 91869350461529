import { Component } from '@angular/core';
import { BrandingComponent } from './branding.component';

/**
 * VerticalSidebarComponent is a standalone component that displays a vertical sidebar navigation.
 */
@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [BrandingComponent],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent { }
