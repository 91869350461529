import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse,
} from "@angular/common/http";

import { Observable, catchError, switchMap, throwError } from "rxjs";
import { AuthService } from "@auth0/auth0-angular";

/**
 * TokenInterceptor intercepts HTTP requests to add an authorization token.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	/** @ignore */
	constructor(private authService: AuthService) {}

	/**
	 * Intercepts HTTP requests to add an authorization token.
	 * @param {HttpRequest<unknown>} request - The HTTP request to intercept.
	 * @param {HttpHandler} next - The next handler in the chain.
	 * @returns {Observable<HttpEvent<unknown>>} An observable of the HTTP event.
	 */
	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		return this.authService.getAccessTokenSilently({ cacheMode: "off" }).pipe(
			switchMap((token) => {
				/**
				 * Clones the request and adds the authorization token.
				 */
				const authReq = request.clone({
					setHeaders: { Authorization: `Bearer ${token}` },
				});
				return next.handle(authReq);
			}),
			catchError((error: HttpErrorResponse) => {
				return throwError(() => error);
			})
		);
	}
}