import { Component, Input } from "@angular/core";
import { CommonModule, NgForOf } from "@angular/common";
import { Router } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";

import { TablerIconsModule } from "angular-tabler-icons";

import { NavService } from "src/app/shared/services/nav.service";
import { INavItem } from "../../../vertical/sidebar/nav-item/nav-item";

/**
 * AppHorizontalNavItemComponent is a standalone component that represents a navigation item in a menu.
 */
@Component({
	selector: "app-horizontal-nav-item",
	standalone: true,
	imports: [TablerIconsModule, CommonModule, MatIconModule, NgForOf],
	templateUrl: "./nav-item.component.html",
})
export class HorizontalNavItemComponent {
    /** Depth level of the navigation item. */
	@Input() depth: any;
    /** Navigation item input. */
	@Input() item!: INavItem;

    /** @ignore */
	constructor(public navService: NavService, public router: Router) {
		if (this.depth === undefined) {
			this.depth = 0;
		}
	}

    /**
     * Handles the selection of a navigation item.
     * @param item The selected navigation item.
     */
	onItemSelected(item: INavItem) {
		if (typeof item.action === 'function') {
			item.action();
		} else if (item.route && item.route.length && (!item.children || !item.children.length)) {
			this.router.navigate([item.route]);
		}
	}
}
