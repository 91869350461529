import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { BlankComponent } from "./layouts/blank/blank.component";
import { FullComponent } from "./layouts/full/full.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { InviteCallbackComponent } from "./modules/pages/authentication/invite-callback/invite-callback.component";
import { AuthCallbackComponent } from "./modules/pages/authentication/auth-callback/auth-callback.component";

const routes: Routes = [
	{
		path: "",
		component: FullComponent,
		children: [
			{
				path: "",
				redirectTo: "/registers/commercialActions",
				pathMatch: "full"
			},
			/*
			{
				path: "dashboard",
				canActivate: [AuthGuard],
				loadChildren: () =>
					import("./modules/pages/pages.module").then(
						(m) => m.PagesModule
					),
			},
			*/
		],
	},
	{
		path: "samples",
		canActivate: [AuthGuard],
		component: FullComponent,
		loadChildren: () => import("./modules/samples/samples.module").then((m) => m.SamplesModule)
	},
	{
		path: "registers",
		canActivate: [AuthGuard],
		component: FullComponent,
		loadChildren: () => import("./modules/registers/registers.module").then((m) => m.RegistersModule)
	},
	{
		path: "",
		canActivate: [],
		component: BlankComponent,
		children: [
			{
				path: "authentication",
				loadChildren: () =>
					import(
						"./modules/pages/authentication/authentication.module"
					).then((m) => m.AuthenticationModule),
			},
		],
	},
	{ path: 'login', component: InviteCallbackComponent },
	{ path: 'callback', component: AuthCallbackComponent },
	{
		path: "**",
		redirectTo: "authentication/error",
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
